/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import api from "../../api";
import { useAuth } from "../../providers/AuthProvider";
import "./addPaymentMethod.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { countries } from "../../common/countries.js";

const AddPaymentMethod = ({ closeModal, onSuccess }) => {
  const { authTokens } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [newPaymentMethod, setNewPaymentMethod] = useState({
    accountName: "",
    bankName: "Bank of America", // Set default value
    email: "default@example.com", // Set default value
    accountNumber: "",
    currency: "USD",
    type: "BANK_ACCOUNT",
    class: "NORMAL",
    address: "",
    city: "Default City", // Set default value
    region: "Default Region", // Set default value
    country: "UG",
    buildingNumber: "123", // Set default value
    swiftCode: "",
    bankAddress: ""
  });

  const bankOptions = [
    { value: "Bank of Africa", label: "Bank of Africa" },
    { value: "UBA Bank", label: "UBA Bank" },
    { value: "Standard Chartered", label: "Standard Chartered" },
    { value: "Equity Bank", label: "Equity Bank" },
    { value: "ABSA Bank", label: "ABSA Bank" }
  ];

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    const initCountry = async () => {
      try {
        // build country options
        const countryOptions = countries.map((country) => ({
          label: country?.name,
          value: country?.code
        }));
        if (countryOptions.length > 0) {
          setCountryOptions(countryOptions);
          const defaultCountry = countryOptions.find((option) => option.value === "UG");
          setSelectedCountry(defaultCountry);
          handleInputChange("country", defaultCountry.value);
        }
      } catch (e) {
        console.log("Error initializing countries:", e);
      }
    };

    initCountry();
  }, []);

  const handleInputChange = (field, value) => {
    setNewPaymentMethod({
      ...newPaymentMethod,
      [field]: value
    });
  };

  const savePaymentMethod = async () => {
    setIsSaving(true);
    try {
      await api.addMudaPaymentMethod({ token: authTokens.IdToken, paymentMethod: newPaymentMethod });
      onSuccess("Payment method added successfully!");
      closeModal();
    } catch (error) {
      console.error("Error adding payment method:", error);
      toast.error("Error adding payment method.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="add_payment_method_modal">
      <div className="add_payment_method_modal_content">
        <h2>Add Payment Method</h2>
        <form onSubmit={handleSubmit(savePaymentMethod)}>
          <div className="form_group">
            <h3>Account Information</h3>
            <div className="form_field">
              <label>Account Name</label>
              <input
                type="text"
                placeholder="Enter account name"
                value={newPaymentMethod.accountName}
                {...register("accountName", { required: "Account name is required" })}
                onChange={(e) => handleInputChange("accountName", e.target.value)}
              />
              {errors.accountName && <span className="input_error">{errors.accountName.message}</span>}
            </div>
            <div className="form_field">
              <label>Account Number</label>
              <input
                type="text"
                placeholder="Enter account number"
                value={newPaymentMethod.accountNumber}
                {...register("accountNumber", { required: "Account number is required" })}
                onChange={(e) => handleInputChange("accountNumber", e.target.value)}
              />
              {errors.accountNumber && <span className="input_error">{errors.accountNumber.message}</span>}
            </div>
            <div className="form_field">
              <label>Class</label>
              <Select
                value={{ value: newPaymentMethod.class, label: newPaymentMethod.class }}
                options={[
                  { value: "NORMAL", label: "Normal" },
                  { value: "PREMIUM", label: "Premium" }
                ]}
                onChange={(option) => handleInputChange("class", option.value)}
              />
            </div>
            <div className="form_field">
              <label>Currency</label>
              <Select
                value={{ value: newPaymentMethod.currency, label: newPaymentMethod.currency }}
                options={[
                  { value: "USD", label: "USD" },
                  { value: "NGN", label: "NGN" },
                  { value: "UGX", label: "UGX" }
                  // Add more currencies as needed
                ]}
                onChange={(option) => handleInputChange("currency", option.value)}
              />
            </div>
            <div className="form_field">
              <label>Country</label>
              <Select
                value={selectedCountry}
                options={countryOptions}
                onChange={(option) => {
                  setSelectedCountry(option);
                  handleInputChange("country", option.value);
                }}
              />
            </div>
          </div>
          <div className="form_group">
            <h3>Bank Information</h3>
            <div className="form_field">
              <label>Bank Name</label>
              <Select
                value={bankOptions.find((option) => option.value === newPaymentMethod.bankName)}
                options={bankOptions}
                onChange={(option) => handleInputChange("bankName", option.value)}
              />
            </div>
            <div className="form_field">
              <label>Swift Code</label>
              <input
                type="text"
                placeholder="Enter swift code"
                value={newPaymentMethod.swiftCode}
                {...register("swiftCode", { required: "Swift code is required" })}
                onChange={(e) => handleInputChange("swiftCode", e.target.value)}
              />
              {errors.swiftCode && <span className="input_error">{errors.swiftCode.message}</span>}
            </div>
            <div className="form_field">
              <label>Bank Address</label>
              <input
                type="text"
                placeholder="Enter bank address"
                value={newPaymentMethod.bankAddress}
                {...register("bankAddress", { required: "Bank address is required" })}
                onChange={(e) => handleInputChange("bankAddress", e.target.value)}
              />
              {errors.bankAddress && <span className="input_error">{errors.bankAddress.message}</span>}
            </div>
          </div>
          <div className="form_group">
            <h3>Address Information</h3>
            <div className="form_field">
              <label>Address</label>
              <input
                type="text"
                placeholder="Enter address"
                value={newPaymentMethod.address}
                {...register("address", { required: "Address is required" })}
                onChange={(e) => handleInputChange("address", e.target.value)}
              />
              {errors.address && <span className="input_error">{errors.address.message}</span>}
            </div>
            <div className="form_field">
              <label>City</label>
              <input
                type="text"
                placeholder="Enter city"
                value={newPaymentMethod.city}
                {...register("city", { required: "City is required" })}
                onChange={(e) => handleInputChange("city", e.target.value)}
              />
              {errors.city && <span className="input_error">{errors.city.message}</span>}
            </div>
            <div className="form_field">
              <label>Region</label>
              <input
                type="text"
                placeholder="Enter region"
                value={newPaymentMethod.region}
                {...register("region", { required: "Region is required" })}
                onChange={(e) => handleInputChange("region", e.target.value)}
              />
              {errors.region && <span className="input_error">{errors.region.message}</span>}
            </div>
            <div className="form_field">
              <label>Building Number</label>
              <input
                type="text"
                placeholder="Enter building number"
                value={newPaymentMethod.buildingNumber}
                {...register("buildingNumber")}
                onChange={(e) => handleInputChange("buildingNumber", e.target.value)}
              />
            </div>
          </div>
          <div className="modal_buttons">
            <button type="submit" disabled={isSaving}>
              {isSaving ? "Saving..." : "Save"}
            </button>
            <button type="button" onClick={closeModal} disabled={isSaving}>
              Cancel
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddPaymentMethod;
