/* eslint-disable react-hooks/exhaustive-deps */
import cn from "classnames";
import { ChevronLeft, Loader2, RefreshCw } from "lucide-react";
import "./escrowedRequest.scss";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { ESCROWED_REQUEST_ACTIONS, useEscrowedRequest, useEscrowedRequestDispatch } from "./escrowedRequestProvider";
import { useEffect, useState } from "react";
import EscrowedRequestDetails from "./details/escrowedRequestDetails";
import EscrowedRequestSellerPops from "./sellerpops/escrowedRequestSellerPops";
import EscrowedRequestBuyerPops from "./buyerpops/escrowedRequestBuyerPops";

const ESCROWED_REQUEST_TABS = {
	DETAILS: "DETAILS",
	SELLER_POPS: "SELLER_POPS",
	BUYER_POPS: "BUYER_POPS",
};

const emptyEscrowedRequestState = {
	activeTab: ESCROWED_REQUEST_TABS.DETAILS,
	refreshingPage: false,
};

const EscrowedRequest = () => {
	const navigate = useNavigate();
	const [escrowedRequestState, setEscrowedRequestState] = useState(emptyEscrowedRequestState);
	const { back, activeRequest, activeRequestId, refreshActiveRequest, isFetchingEscrowedRequest } = useEscrowedRequest();
	const { activeRequestId: activeRequestIdInURL } = useParams();
	const escrowedRequestDispatch = useEscrowedRequestDispatch();


	useEffect(() => {
		//If there is a request id in the url
		if (!_.isEmpty(activeRequestIdInURL)) {
			//If there is no activeRequest, fetch the active request
			if (_.isEmpty(activeRequest)) {
				escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "activeRequestId", value: activeRequestIdInURL });
				updateState({ key: "refreshingPage", value: true });
				refreshActiveRequest();
				return;
			}

			//If there is an active request with a different id, fetch active request
			if (activeRequestIdInURL != activeRequestId) {
				escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "activeRequestId", value: activeRequestIdInURL });
				updateState({ key: "refreshingPage", value: true });
				refreshActiveRequest();
				return;
			}

			//If the active request does not have pops, fetch active request
			if (_.isEmpty(activeRequest._proofOfPayments)) {
				updateState({ key: "refreshingPage", value: true });
				refreshActiveRequest();
				return;
			}
		}
	}, [activeRequestIdInURL]);

	//When active request changes, if we were refreshing, clear refreshing state
	useEffect(() => {
		if(escrowedRequestState.refreshingPage){
			updateState({ key: "refreshingPage", value: false });
		}
	}, [activeRequest]);


	const updateState = ({ key, value }) => {
		const newState = { ...escrowedRequestState };
		newState[key] = value;
		setEscrowedRequestState(newState);
	};

	// const updateStates = (states) => {
	// 	const newState = { ...escrowedRequestState };
	// 	states.forEach(({ key, value }) => {
	// 		newState[key] = value;
	// 	});
	// 	setEscrowedRequestState(newState);
	// };

	const loadingPage = () => {
		return isFetchingEscrowedRequest || escrowedRequestState.refreshingPage;
	};

	const reloadPage = async () => {
		//refresh escrow pending
		refreshActiveRequest();
	};

	const goBack = () => {
		if (!_.isEmpty(back)) {
			navigate(back);
		} else {
			navigate("/escrow/pending");
		}
	};

	const switchToTab = (tab) => {
		updateState({ key: "activeTab", value: tab });
	};

	return (
		<div id="escrowed_request_page">
			<div className="back_container">
				<ChevronLeft className="back_icon" onClick={goBack} />
			</div>

			<div className="heading">
				<div className="title">Escrowed Transaction Details</div>
				<RefreshCw onClick={reloadPage} data-testid="escrowreq-refresh" className={cn("refresh_icon", { rotating: loadingPage() })} />
			</div>

			{loadingPage() && (
				<div className="escrowed_request_loader_container">
					Loading transaction details...
					<Loader2 className="escrowed_request_loader" />
				</div>
			)}

			{!loadingPage() && (
				<div className="body">
					<div className="tabs">
						<div className="tab_header">
							<div
								onClick={() => switchToTab(ESCROWED_REQUEST_TABS.DETAILS)}
								className={cn("tab", {
									active: escrowedRequestState.activeTab === ESCROWED_REQUEST_TABS.DETAILS,
								})}>
								Details
							</div>
							<div
							    data-testid="escrow-sellerpops-tab"
								onClick={() => switchToTab(ESCROWED_REQUEST_TABS.SELLER_POPS)}
								className={cn("tab", {
									active: escrowedRequestState.activeTab === ESCROWED_REQUEST_TABS.SELLER_POPS,
								})}>
								Seller PoPs
							</div>
							<div
							    data-testid="escrow-buyerpops-tab"
								onClick={() => switchToTab(ESCROWED_REQUEST_TABS.BUYER_POPS)}
								className={cn("tab", {
									active: escrowedRequestState.activeTab === ESCROWED_REQUEST_TABS.BUYER_POPS,
								})}>
								Buyer PoPs
							</div>
						</div>

						{escrowedRequestState.activeTab === ESCROWED_REQUEST_TABS.DETAILS && <EscrowedRequestDetails></EscrowedRequestDetails>}
						{escrowedRequestState.activeTab === ESCROWED_REQUEST_TABS.SELLER_POPS && <EscrowedRequestSellerPops></EscrowedRequestSellerPops>}
						{escrowedRequestState.activeTab === ESCROWED_REQUEST_TABS.BUYER_POPS && <EscrowedRequestBuyerPops></EscrowedRequestBuyerPops>}
					</div>
				</div>
			)}
		</div>
	);
};
export default EscrowedRequest;
