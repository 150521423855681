import { X } from 'lucide-react';
import PropTypes from 'prop-types';
import './popPreviewModal.scss';

const PopPreviewModal = ({ pop, onClose, viewPaymentMethod }) => {
  return (
    <div className="pop_preview_modal">
      <div className="pop_preview_modal_content">
        <div className="pop_preview_modal_header">
          <div className="label">Proof of Payment Preview</div>
          <X className="close" onClick={onClose} />
        </div>
        <div className="pop_preview_modal_body">
          <img src={pop.fileUrl} alt="Proof of Payment" />
          <div className="pop-details">
            <p>Amount: {pop.amount} {pop.currency}</p>
            <p>Status:  
              {pop.popState === "PENDING" && <span className="pop_status pending">pending</span>}
              {pop.popState === "VERIFIED" && <span className="pop_status verified">verified</span>}
              {pop.popState === "REJECTED" && <span className="pop_status rejected">rejected</span>}
            </p>
            <p>Paid to: {viewPaymentMethod(pop.paymentMethod)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

PopPreviewModal.propTypes = {
  pop: PropTypes.shape({
    fileUrl: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    popState: PropTypes.string.isRequired,
    paymentMethod: PropTypes.object.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  viewPaymentMethod: PropTypes.func.isRequired,
};

export default PopPreviewModal;